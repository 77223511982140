<!-- Cobros de Remesas por transferencia -->

<template>
  <div class="cobros_pagos_transferencias">   
      <div class="contenedor centrado">
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="$emit('onEvent', { accion:'cerrar' })">
            </base_Header>                  
          </div>

          <!-- Contenido -->
          <div class="contenedor conflex" style="width:600px"> 
         
              <!-- Grid -->
              <base_Fgrid
                v-model="selected"
                :padre="stName" 
                :Entorno="Entorno"  
                height="300px"        
                :items-per-page=-1 
                show-select 
                single-select
                hide-default-footer 
                fixed-header     
                dense
                @onEvent="event_Grid">

                <!-- Slot botonera Top -->
                <template v-slot:top_btns="{ }">         
                  <div class="conflex" style="padding:10px 0 0 10px;">
                    <v-btn
                      v-bind="$cfg.btn.busca_with_text"  
                      @click="contablizar()" 
                      :disabled="!selected.length"         
                      title="">
                        Contabilizar
                    </v-btn>        
                    
                    <compfecha
                      v-if="selected.length"
                      style="padding-left:10px;max-width:200px"
                      :schema="schema.ctrls.fh"
                      label="Fecha de cobro"
                      :edicion=true>
                    </compfecha>                   
                  </div>
                </template>             
              </base_Fgrid>           
          </div>
        </v-sheet> 
      </div>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
    
  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, compfecha },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:String, default: ''},
      accionRow: { type:Object, default: null},
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            t: { f:'t', label:'', value:""},
            fh: { f:'fh', label:'', value:"", 
              comp: {
                type:"fecha",
                format:"fh" 
              }
            }
          }
        },
        api:'',
        stName:'stFcobros_pagos_transferencias',
        headers: {},
        selected:[],
        btra_permisos:{ extra:{}},

        dialog:false,
        itemsCC: [],
      };
    },


    created() {
      this.ini_data();
    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Ordenes de Pago / Transferencias"; 
        this.Entorno.grid.autoload= true;

        this.headers = {
          header: [
            { text: "Orden", value: "orden", witdh: "30%" },
            { text: "Creada", value: "fhhr", witdh: "10%", filtro:'fechahr' },
            { text: "Fecha", value: "fh", witdh: "10%", filtro:'fecha' },
            { text: "Facturas", value: "fras", witdh: "30%" },
            { text: "Importe", value: "tot", witdh: "30%", filtro:'num', arg:2 }
         ],

        };

        this.Entorno.grid.headers = this.set_headers();
      
        // items adicionales
        // 
        this.itemsCC= [];
      },


      //
      async ini_component() {       
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'pagos',
          fnt:'ordenpago'          
        }};
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });     
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }       
     
        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r }); 
      },


      contablizar() {  
        this.$root.$alert
        .open("¿Contabilizamos orden de cobro?<br><b>Nº: " + this.selected[0].orden + "</b>", "confirm")
        .then(r => { if (r) this.contabilizar_continue() });
      },
      
      async contabilizar_continue() {
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'pagos',
          fnt:'ordenpago_conta',
          id:this.selected[0].id,
          fhp:this.schema.ctrls.fh.value
        }};
      
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });      
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }     

        this.$root.$alert.open('Orden de Pago contabilizada. Asiento Nº ' + apiResult.r[0], 'success');

        // recargo finder cuentas
        this.ini_component();

        // blanqueo selected
        this.selected= [];
      }

    },


    watch: {
      selected() {        
        if (!this.selected.length) return;
        this.schema.ctrls.fh.value= this.selected[0].fh;
      }
    }
  };
</script>
